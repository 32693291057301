import React from "react";
import styled from "styled-components";

const ErrorBoundaryFallback = () => (
  <Wrapper>
    <div
      style={{
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h1>Oh no! Something has cracked…</h1>
      <p>
        That’s not supposed to happen! Just like we take care to keep our vases
        intact, we’re working hard to fix this issue on our website. Our team
        has been notified and is already on it to get everything running
        smoothly again.
      </p>
      <h3 style={{ marginTop: 12 }}>What can you do?</h3>
      <ul>
        <li>
          Try refreshing the page. Sometimes that’s all it takes to make things
          beautiful again.
        </li>
        <li>
          If that doesn’t work, please check back later. We’re doing our best to
          piece everything back together.
        </li>
      </ul>
    </div>
  </Wrapper>
);

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #846f55;
  padding-bottom: 200px;

  h1,
  h3,
  p,
  li {
    color: white;
    max-width: 600px;
    text-align: center;
  }

  p,
  li {
    font-size: 16px;
  }

  li {
    list-style: disc;
    margin-left: 24px;
  }
`;

export default ErrorBoundaryFallback;
