import axios from "axios";

export const sendExceptionToSlack = (message) => {
  if (process.env.REACT_APP_ENVIRONMENT === "local") return;
  let project = "Bombyxx";
  if (process.env.REACT_APP_ENVIRONMENT !== "production") project += " DEV";
  axios.post("https://guardian.digitalkiss.nl/api/slack/exception", {
    project,
    message,
  });
};
